<template>
  <div class="live-player">
    <iframe
      :src="src"
      allow="autoplay"
      allowfullscreen
    />
  </div>
</template>

<script>
import { stringify } from '@billjs/query-string';

export default {
  name: 'Live',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    src() {
      if (this.data.src.includes('livestream.com')) {
        return `${this.data.src}player/?${stringify(this.data.params)}`;
      }
      const urlObj = new URL(this.data.src);
      if (this.data.params.autoPlay === 'true') {
        urlObj.searchParams.set('autoplay', 1);
        urlObj.searchParams.set('mute', 1);
      }
      return urlObj.toString();
    },
  },
  mounted() {
    this.$store.commit('SET_AUDIO', false);
    this.$store.commit('SET_EMBED', false);

    if (!this.data.avoidScroll) {
      window.scroll(0, 0);
    }
  },
};
</script>

<style lang="scss">
.live-player {
  position: relative;
  margin: 0 auto;
  @include aspect-ratio(1395, 788);
  background: var(--grey-xxd);

  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}
</style>
